import React from 'react'
import { StatusTypes, useOnlineStatus } from 'common-utils'

import { ViewModeType } from 'src/network'
import LeaderboardStore from 'src/utils/LeaderboardStore'

const PausedPage = React.lazy(() => import('./Paused'))
const templates = [() => null, React.lazy(() => import('./EBH/EBHBrown')), React.lazy(() => import('./EBH/EBHLight'))]

interface TvAppProps {
   viewMode: ViewModeType
   liveId: string
}

const TvApp = ({ viewMode, liveId }: TvAppProps) => {
   const store = React.useMemo(() => new LeaderboardStore(viewMode, liveId), [viewMode, liveId])
   const data = React.useSyncExternalStore(store.subscribe, () => store.data)

   useOnlineStatus(isOnline => {
      if (isOnline) store.reload()
   })

   if (!data || data.templateId == null || data.templateId >= templates.length) return null

   const isPaused = [StatusTypes.Draft, StatusTypes.Approved, StatusTypes.Paused, StatusTypes.Recalculating].includes(
      data?.status
   )
   const Template = isPaused && viewMode === ViewModeType.LIVE ? PausedPage : templates.at(data.templateId)!

   return (
      <React.Suspense>
         <Template leaderboard={data} />
      </React.Suspense>
   )
}

export default TvApp

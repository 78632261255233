import React from 'react'

import { ViewModeType } from './network'
import TvApp from './templates/TvApp'
import EveriApp from './mobile'

/**
 * Simple router.
 * Currently, it enough. But if things become more complicated
 * consider to use lightweight library like https://npmjs.com/package/wouter
 */
function App() {
   const [, mode, liveId] = location.pathname.split('/')
   const viewMode = mode.toLowerCase() as ViewModeType
   let routeComponent = null

   switch (viewMode) {
      case ViewModeType.LIVE:
      case ViewModeType.PREVIEW:
         routeComponent = <TvApp viewMode={viewMode} liveId={liveId} />
         break
      case ViewModeType.EVERI:
         routeComponent = <EveriApp liveId={liveId} />
   }

   return routeComponent
}

export default App

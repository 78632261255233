import { groupBy } from 'lodash'

interface SortedSet<T> {
   [Symbol.iterator](): IterableIterator<T>
   readonly [Symbol.toStringTag]: string

   size: number
   asArray: T[]

   add(value: T): this
   delete(value: T): boolean
   has(value: T): boolean
   values(): IterableIterator<T>
   forEach(callback: (value: T, value2: T, set: SortedSet<T>) => void, thisArg?: any): void
   map<U>(callback: (value: T, index: number, array: T[]) => U, thisArg?: any): U[]
}

type LeaderboardItem = { key: string; updatedOn: Date; rank: number; score: number }

export default class SortedLeaderboard<T extends LeaderboardItem> implements SortedSet<T> {
   readonly [Symbol.toStringTag] = 'SortedLeaderboard';
   [Symbol.iterator] = this.values
   #comparator = (a: T, b: T) => {
      let dir = b.score - a.score
      if (dir === 0) dir = b.updatedOn.getDate() - a.updatedOn.getDate()
      return dir
   }
   #keyBy = (rec: T) => rec.key

   #sizeLimit = 0
   #sortedValues: Array<T> = []
   #map = new Map()

   constructor(values?: readonly T[] | null, size?: number, comparator?: Parameters<typeof Array.prototype.sort>[0]) {
      if (values) this.#map = new Map(values.map(it => [this.#keyBy(it), it]))
      if (comparator) this.#comparator = comparator

      this.size = size ?? this.#map.size
      return this
   }

   get size() {
      return this.#map.size
   }

   set size(sizeLimit) {
      if (sizeLimit < 0) throw new Error('SortedSet.size must be positive number')
      this.#sizeLimit = sizeLimit
      this.#trimSize()
   }

   get asArray() {
      return this.#sortedValues
   }

   add(...values: T[]): this {
      values.forEach(value => {
         // strategy: replace old value
         const key = this.#keyBy(value)
         this.#map.set(key, value)
      })

      this.#trimSize()

      return this
   }

   delete(value: T): boolean {
      if (this.#map.delete(this.#keyBy(value))) {
         this.#sortedValues = this.#sortedMapValues()
         return true
      }
      return false
   }

   has(value: T): boolean {
      return this.#map.has(this.#keyBy(value))
   }

   values() {
      return this.#sortedValues.values()
   }

   map<U>(callback: (value: T, index: number, array: T[]) => U, thisArg?: any): U[] {
      return this.#sortedValues.map(callback, thisArg)
   }

   slice(start?: number, end?: number): T[] {
      return this.#sortedValues.slice(start, end)
   }

   forEach(callback: (value: T, value2: T, set: SortedSet<T>) => void, thisArg?: any): void {
      this.#sortedValues.forEach(it => callback(it, it, this), thisArg)
   }

   #sortedMapValues() {
      const result = [...this.#map.values()].sort(this.#comparator)
      const keyByScore = groupBy(result, 'score')
      result.forEach((it, idx) => {
         it.rank = idx + 1
         it.tie = keyByScore[it.score].length > 1
      })

      return result
   }

   #trimSize() {
      this.#sortedValues = this.#sortedMapValues()

      if (this.#map.size <= this.#sizeLimit) return
      while (this.#map.size > this.#sizeLimit) this.delete(this.#sortedValues.pop() as T)
   }
}

import { OrderTypes } from 'common-utils'

import { API, DataTopicType, Leaderboard, Player, PubSub, ViewModeType } from 'src/network'
import SortedLeaderboard from './SortedSet'
import { isEqual, omit } from 'lodash'

export type PlayerSetItem = Player & {
   key: string
   updatedOn: Date
   playerId: string
   rank: number
   scoreFormatted: string
}
export type LeaderboardData = Omit<Leaderboard, 'records'> & {
   colors?: string[]
   records: SortedLeaderboard<PlayerSetItem>
}

interface WSSMessage {
   records: Player[]
   sequenceId: number
}

export interface ILeaderboardStore {
   data: LeaderboardData | null
   subscribe(onStoreChange: () => void): () => void
   notify(): void
}

const WSS_CANCEL_TOKEN = Symbol('wss negotiation')
const WSS_GROUP_CANCEL_TOKEN = Symbol('wss group negotiation')
const BOARD_CANCEL_TOKEN = Symbol('fetch leaderboard')

const sortAsc = <T extends PlayerSetItem>(a: T, b: T) =>
   a.score - b.score || a.updatedOn.getTime() - b.updatedOn.getTime()
const sortDesc = <T extends PlayerSetItem>(a: T, b: T) =>
   b.score - a.score || a.updatedOn.getTime() - b.updatedOn.getTime()

export default class LeaderboardStore implements ILeaderboardStore {
   static #mapper = (rec: Player) =>
      ({
         ...rec,
         key: `${rec.playerId}_${rec.hash}`,
         updatedOn: new Date(rec.updatedOn),
         rank: +rec.rank,
         scoreFormatted: rec.score.toLocaleString('en-US', { maximumFractionDigits: 0 }),
      } as PlayerSetItem)
   #userId = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10)
   #lastReceivedSequenceId = -1
   #data: LeaderboardData | null = null
   #listeners = new Set<() => void>()
   #messageQueue: WSSMessage[] = []

   constructor(private viewMode: ViewModeType, private liveId: string) {
      this.reload()
      if (process.env.REACT_APP_QA_PAGE) {
         // @ts-ignore
         window['LBStore'] = this
      }
   }

   get data() {
      return this.#data
   }

   subscribe = (listener: () => void) => {
      this.#listeners.add(listener)
      return () => this.#listeners.delete(listener)
   }

   notify() {
      if (this.#data) this.#data = { ...this.#data }
      this.#listeners.forEach(listener => listener())
   }

   reload() {
      API.http.abortRequest(BOARD_CANCEL_TOKEN)

      if (this.viewMode === ViewModeType.LIVE) this.#realTimeSubscribe(this.liveId)
      else this.#fetchInitialData()
   }

   #fetchInitialData() {
      API.fetchLeaderboardByViewMode(this.viewMode, this.liveId, { cancelToken: BOARD_CANCEL_TOKEN }).then(
         ({ data }) => {
            if (
               this.#data &&
               // !isEqual(omit(data, 'records'), omit(this.#data, 'records'))
               !isEqual(omit(data, 'records', 'sequenceId'), omit(this.#data, 'records', 'sequenceId'))
            ) {
               // leaderboard configuration has been changed, it's better to restart the app
               return window.location.reload()
            }
            this.#data = {
               ...data,
               colors: data.winnersColorSettings?.reduce(
                  (acc, it) => [...acc, ...Array(it.end - it.start + 1).fill(it.hexValue)],
                  [] as string[]
               ),
               records: new SortedLeaderboard(
                  data.records.map(LeaderboardStore.#mapper),
                  data.playersNumber,
                  data.order === OrderTypes.ASC ? sortAsc : sortDesc
               ),
            }

            this.#lastReceivedSequenceId = data.sequenceId
            this.notify()

            // assuming messages via WS received in correct order, handle missed events
            this.#messageQueue.filter(it => it.sequenceId > this.#lastReceivedSequenceId).map(this.handleChange)
            this.#messageQueue = []
         }
      )
   }

   #realTimeSubscribe(groupId: string) {
      PubSub.bus.clearListeners()
      this.#lastReceivedSequenceId = -1

      PubSub.connect(async () => {
         API.http.abortRequest(WSS_CANCEL_TOKEN)
         try {
            const { data: token } = await API.webNotifications.webNotificationNegotiate(
               { userId: this.#userId },
               { cancelToken: WSS_CANCEL_TOKEN }
            )
            return token.accessUrl!
         } catch (e) {
            // Websocket will fail with this url and retry after a timeout
            return 'wss://fallback-retry'
         }
      })

      PubSub.bus.on('connection:open', async () => {
         // NOTE: should be executed every time when connection (re)opened
         try {
            API.http.abortRequest(WSS_GROUP_CANCEL_TOKEN)
            await API.webNotifications.webNotificationGroupsNegotiate(
               { userId: this.#userId, groupId },
               { cancelToken: WSS_GROUP_CANCEL_TOKEN }
            )

            this.#fetchInitialData()
         } catch (e) {
            // Websocket opened but API is down - close current connection and retry
            setTimeout(() => PubSub.reconnect(), PubSub.config.minReconnectionDelay)
         }
      })
      PubSub.bus.on<WSSMessage>(DataTopicType.PlayerScoreChanged, event => this.handleChange(event))
      PubSub.bus.on(DataTopicType.LeaderboardStatusChanged, () => window.location.reload())
   }

   handleChange = (message: WSSMessage = { records: [], sequenceId: 0 }) => {
      // should wait until main data received
      if (!this.#data || this.#lastReceivedSequenceId === -1) return this.#messageQueue.push(message)

      const { records, sequenceId } = message
      // check sequence
      if (++this.#lastReceivedSequenceId !== sequenceId) return this.reload()

      this.#data.records.add(...records.map(LeaderboardStore.#mapper))
      this.notify()
   }
}

import { Api, HttpClient, RequestParams, EveriLeaderboardParams } from './generated/Api'
import { ViewModeType } from './types'

class LeaderboardsApi extends Api<unknown> {
   fetchLeaderboardByViewMode(
      viewModeType: ViewModeType,
      liveId: string | EveriLeaderboardParams,
      params: RequestParams = {}
   ) {
      switch (viewModeType) {
         case ViewModeType.LIVE:
            return this.leaderboards.tvLeaderboard(liveId as string, params)
         case ViewModeType.PREVIEW:
            return this.leaderboards.previewTvLeaderboard(liveId as string, params)
         default:
            return Promise.reject('Unknown view mode')
      }
   }
}

const API = new LeaderboardsApi(
   new HttpClient({
      baseUrl: process.env.REACT_APP_API_URL,
      baseApiParams: {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY!,
            'X-Version': '1.0',
         },
      },
   })
)

export default API

var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateWrapper = (obj, member, setter, getter) => ({
  set _(value) {
    __privateSet(obj, member, value, setter);
  },
  get _() {
    return __privateGet(obj, member, getter);
  }
});

// src/PubSubService.ts
import ReconnectingWebSocket from "reconnecting-websocket";

// src/WSMessageBus.ts
var MessageEmitter = Object.freeze({
  listeners: /* @__PURE__ */ new Map(),
  clearListeners() {
    this.listeners.clear();
  },
  emit(event, eventData, ...args) {
    if (!this.listeners.has(event))
      return;
    for (const it of this.listeners.get(event))
      it(eventData, ...args);
  },
  on(event, listener) {
    const events = Array.isArray(event) ? event : [event];
    events.forEach(
      (name) => this.listeners.has(name) ? this.listeners.get(name).add(listener) : this.listeners.set(name, /* @__PURE__ */ new Set([listener]))
    );
  },
  once(eventName, listener) {
    const fn = (eventData) => {
      this.off(eventName, fn);
      listener(eventData);
    };
    this.on(eventName, fn);
  },
  when(eventName, timeout) {
    return new Promise((res, rej) => {
      let timeoutHandle;
      const fn = (eventData) => {
        clearTimeout(timeoutHandle);
        this.off(eventName, fn);
        res(eventData);
      };
      if (timeout != null)
        timeoutHandle = setTimeout(() => {
          this.off(eventName, fn);
          rej("timeout");
        }, timeout);
      this.on(eventName, fn);
    });
  },
  off(event, listener) {
    const events = Array.isArray(event) ? event : [event];
    events.forEach((it) => {
      var _a;
      return (_a = this.listeners.get(it)) == null ? void 0 : _a.delete(listener);
    });
  }
});
var WSMessageBus_default = MessageEmitter;

// src/PubSubService.ts
var __DEV__ = process.env.NODE_ENV === "development";
var log = __DEV__ ? console.log : () => void 0;
var DEFAULT_CONFIG = {
  maxReconnectionDelay: 1e4,
  minReconnectionDelay: 2e3,
  minUptime: 5e3,
  reconnectionDelayGrowFactor: 1.3,
  connectionTimeout: 4e3,
  maxRetries: Infinity,
  maxEnqueuedMessages: Infinity,
  startClosed: false,
  debug: __DEV__
};
var _rws, _config, _url, _urlProvider, _token, _lastReceivedSequenceId, _ackId;
var PubSubService = class {
  constructor() {
    this.bus = WSMessageBus_default;
    __privateAdd(this, _rws, void 0);
    __privateAdd(this, _config, DEFAULT_CONFIG);
    __privateAdd(this, _url, void 0);
    __privateAdd(this, _urlProvider, void 0);
    __privateAdd(this, _token, void 0);
    __privateAdd(this, _lastReceivedSequenceId, 0);
    __privateAdd(this, _ackId, 0);
  }
  connect(urlProvider) {
    this.disconnect();
    if (!urlProvider)
      throw Error("Invalid URL provider");
    __privateSet(this, _urlProvider, urlProvider);
    __privateSet(this, _rws, new ReconnectingWebSocket(
      async () => {
        if (__privateGet(this, _url) && __privateGet(this, _token)) {
          __privateGet(this, _url).search = `?awps_connection_id=${__privateGet(this, _token).connectionId}&awps_reconnection_token=${__privateGet(this, _token).reconnectionToken}`;
          __privateSet(this, _token, void 0);
        } else {
          __privateSet(this, _url, new URL(await this.getNextUrl(urlProvider)));
          __privateSet(this, _lastReceivedSequenceId, 0);
        }
        return __privateGet(this, _url).href;
      },
      "json.reliable.webpubsub.azure.v1",
      __privateGet(this, _config)
    ));
    __privateGet(this, _rws).addEventListener("message", this.onMessage.bind(this));
    __privateGet(this, _rws).addEventListener("close", this.onClose.bind(this));
    __privateGet(this, _rws).addEventListener("error", this.onError.bind(this));
    __privateGet(this, _rws).addEventListener("open", this.onOpen.bind(this));
  }
  reconnect() {
    var _a;
    (_a = __privateGet(this, _rws)) == null ? void 0 : _a.reconnect();
  }
  disconnect() {
    var _a;
    (_a = __privateGet(this, _rws)) == null ? void 0 : _a.close();
    __privateSet(this, _url, void 0);
  }
  send(message) {
    var _a;
    (_a = __privateGet(this, _rws)) == null ? void 0 : _a.send(JSON.stringify(message));
  }
  async invoke(message) {
    var _a;
    const ackId = __privateWrapper(this, _ackId)._++;
    this.send({ ...message, ackId });
    const result = await this.bus.when(`ack:${ackId}`).catch((reason) => {
      if (reason === "timeout")
        throw { type: "ack", ackId, success: false, error: { name: "Timeout" } };
      throw reason;
    });
    if (!result.success || ((_a = result.error) == null ? void 0 : _a.name) !== "Duplicate")
      throw result;
    return result;
  }
  async joinGroup(group) {
    return this.invoke({ type: "joinGroup", group });
  }
  async leaveGroup(group) {
    return this.invoke({ type: "leaveGroup", group });
  }
  get config() {
    return { ...__privateGet(this, _config) };
  }
  onMessage(event) {
    try {
      const body = JSON.parse(event.data);
      log("body = ", body);
      switch (body.type) {
        case "system":
          if (body.event === "connected")
            __privateSet(this, _token, { connectionId: body.connectionId, reconnectionToken: body.reconnectionToken });
          else if (body.event === "disconnected")
            this.disconnect();
          break;
        case "ack":
          this.bus.emit(`ack:${body.ackId}`, body);
          break;
        case "message": {
          const { sequenceId, data } = body;
          if (sequenceId) {
            if (sequenceId > __privateGet(this, _lastReceivedSequenceId))
              __privateSet(this, _lastReceivedSequenceId, sequenceId);
            this.send({ type: "sequenceAck", sequenceId: __privateGet(this, _lastReceivedSequenceId) });
          }
          const { topic, message } = data;
          this.bus.emit(topic, message);
          break;
        }
      }
    } catch (error) {
      log("WSService:onMessage: ", error);
    }
  }
  getNextUrl(urlProvider) {
    if (typeof urlProvider === "string") {
      return Promise.resolve(urlProvider);
    }
    if (typeof urlProvider === "function") {
      const url = urlProvider();
      if (typeof url === "string")
        return Promise.resolve(url);
      if (url.then)
        return url;
    }
    throw Error("Invalid URL");
  }
  onOpen(event) {
    log("WSService:onOpen: ", event);
    this.bus.emit("connection:open", event);
  }
  onClose({ code, reason, wasClean }) {
    log("WSService:onClose: ", code, reason, wasClean);
    if (code === 1008 && __privateGet(this, _urlProvider))
      this.connect(__privateGet(this, _urlProvider));
  }
  onError({ error, message }) {
    log("WSService:onError: ", message, error);
  }
};
_rws = new WeakMap();
_config = new WeakMap();
_url = new WeakMap();
_urlProvider = new WeakMap();
_token = new WeakMap();
_lastReceivedSequenceId = new WeakMap();
_ackId = new WeakMap();
var PubSub = new PubSubService();
var PubSubService_default = PubSub;

// src/types.ts
var DataTopicType = /* @__PURE__ */ ((DataTopicType2) => {
  DataTopicType2["AudienceFileUploaded"] = "AudienceFileUploaded";
  DataTopicType2["ParticipantsDownloadRequested"] = "ParticipantsDownloadRequested";
  DataTopicType2["LeaderboardStatusChanged"] = "LeaderboardStatusChanged";
  DataTopicType2["PlayerScoreChanged"] = "PlayerScoreChanged";
  return DataTopicType2;
})(DataTopicType || {});

// src/usePubSub.ts
import { useEffect, useRef } from "react";
var usePubSub = (event, handler) => {
  const callbackRef = useRef(handler);
  const eventDeps = typeof event === "string" ? event : event.join("");
  useEffect(() => {
    callbackRef.current = handler;
  }, [handler]);
  useEffect(() => {
    const listener = (eventData, ...params) => {
      var _a;
      return (_a = callbackRef.current) == null ? void 0 : _a.call(callbackRef, eventData, ...params);
    };
    PubSubService_default.bus.on(event, listener);
    return () => PubSubService_default.bus.off(event, listener);
  }, [eventDeps]);
};
export {
  DataTopicType,
  PubSubService_default as default,
  usePubSub
};

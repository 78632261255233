import type { GenericViewResponse, PlayerViewResponse } from './generated/Api'

type GenericRecords = GenericViewResponse['records']
type Record = GenericRecords[0]

export type Player = Record
export type PlayerView = PlayerViewResponse

export interface Leaderboard extends GenericViewResponse {
   records: Player[]
}

export enum ViewModeType {
   LIVE = 'live',
   PREVIEW = 'preview',
   EVERI = 'm',
}

export const LeaderboardLiveStatus = 3

import React from 'react'

const MobileLeaderboard = React.lazy(() => import('./MobileLeaderboard'))

const EveriApp = (props: { liveId: string }) => (
   <React.Suspense>
      <MobileLeaderboard {...props} />
   </React.Suspense>
)

export default EveriApp

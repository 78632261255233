// src/constants.ts
var StatusTypes = /* @__PURE__ */ ((StatusTypes2) => {
  StatusTypes2[StatusTypes2["Draft"] = 1] = "Draft";
  StatusTypes2[StatusTypes2["Approved"] = 2] = "Approved";
  StatusTypes2[StatusTypes2["Live"] = 3] = "Live";
  StatusTypes2[StatusTypes2["Paused"] = 4] = "Paused";
  StatusTypes2[StatusTypes2["Recalculating"] = 5] = "Recalculating";
  StatusTypes2[StatusTypes2["Finished"] = 6] = "Finished";
  StatusTypes2[StatusTypes2["Archived"] = 7] = "Archived";
  return StatusTypes2;
})(StatusTypes || {});
var AnimationTypes = /* @__PURE__ */ ((AnimationTypes2) => {
  AnimationTypes2[AnimationTypes2["Static"] = 1] = "Static";
  AnimationTypes2[AnimationTypes2["Flip"] = 2] = "Flip";
  return AnimationTypes2;
})(AnimationTypes || {});
var OrderTypes = /* @__PURE__ */ ((OrderTypes2) => {
  OrderTypes2[OrderTypes2["ASC"] = 1] = "ASC";
  OrderTypes2[OrderTypes2["DESC"] = 2] = "DESC";
  return OrderTypes2;
})(OrderTypes || {});
var PropertyTypes = /* @__PURE__ */ ((PropertyTypes2) => {
  PropertyTypes2[PropertyTypes2["WYNNLasVegas"] = 1] = "WYNNLasVegas";
  PropertyTypes2[PropertyTypes2["EncoreBostonHarbor_EVT"] = 2] = "EncoreBostonHarbor_EVT";
  return PropertyTypes2;
})(PropertyTypes || {});
var MetricSourceFilterTypes = /* @__PURE__ */ ((MetricSourceFilterTypes2) => {
  MetricSourceFilterTypes2["SlotPointsManufacturer"] = "2";
  MetricSourceFilterTypes2["SlotPointsSection"] = "3";
  MetricSourceFilterTypes2["TierCreditsGameType"] = "8";
  return MetricSourceFilterTypes2;
})(MetricSourceFilterTypes || {});
var MetricTypes = /* @__PURE__ */ ((MetricTypes2) => {
  MetricTypes2[MetricTypes2["SlotPoints"] = 1] = "SlotPoints";
  MetricTypes2[MetricTypes2["TierCredits"] = 2] = "TierCredits";
  return MetricTypes2;
})(MetricTypes || {});

// src/useOnlineStatus.ts
import React from "react";
import { debounce } from "lodash";
function useOnlineStatus(handler, element = window) {
  const savedHandler = React.useRef(handler);
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  React.useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported)
      return;
    const debouncedHandler = debounce(() => {
      var _a;
      return (_a = savedHandler.current) == null ? void 0 : _a.call(savedHandler, navigator.onLine);
    }, 1e3, {
      leading: false,
      trailing: true,
      maxWait: 100
    });
    window.addEventListener("online", debouncedHandler);
    window.addEventListener("offline", debouncedHandler);
    return () => {
      window.removeEventListener("online", debouncedHandler);
      window.removeEventListener("offline", debouncedHandler);
    };
  }, [element]);
}
export {
  AnimationTypes,
  MetricSourceFilterTypes,
  MetricTypes,
  OrderTypes,
  PropertyTypes,
  StatusTypes,
  useOnlineStatus
};
